import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

const Auth = (props) => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  const search = window.location.search;
  const params = search && decodeURIComponent(search) ? new URLSearchParams(decodeURIComponent(search)) : null;
  const id = params ? params.get('id') : null;
  useEffect(() =>{{
    if(token){
      localStorage.clear();
    }
    if(!id){
      return navigate('/PageNotFound');
    }
    Auth()
  }}, [])

  const Auth = async () => {
    try {
        const result = await axios.post(`${process.env.REACT_APP_AIRBOX_BE_URL}/customer/guest/verifyShortId`, {
            id: id,
            flow: 'link'
        }, {
          headers: {
            'content-language': 'en',
          }
        });
        if(result && result.data && result.data.data && result.data.data.id && result.data.data.mobile){
          return navigate(`/login?mobile=${result.data.data.mobile}&id=${result.data.data.id}`);
        }else{
          return navigate('/PageNotFound');
        }
    } catch (error) {
      return navigate('/PageNotFound');
    }
  }
}

export default Auth