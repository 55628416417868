import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

const Home = (props) => {
  const [state, setState] = useState(5);
  const [isProcessing, setIsProcessing] = useState(true);
  const [msg, setMsg] = useState('');
  const [retry, setRetry] = useState(8);
  const navigate = useNavigate();
  const [msgClass, setMsgClass] = useState('error-msg-cls');
  const token = JSON.parse(localStorage.getItem('token'));
  const id = JSON.parse(localStorage.getItem('id'));

  const loaderMsgArray = ["Processing", "Closing", "Opening"];

  useEffect(() =>{{
    if(!token || !id){
      return navigate('/PageNotFound');
    }

    async function fetchData(){
      try {
        const result = await axios.get(`${process.env.REACT_APP_AIRBOX_BE_URL}/airbox/getLiveState?airboxId=${id}`, {
          headers: {
            'authorization': `Bearer ${token}`,
            'content-language': 'en',
            'utcoffset': 330,
          }
        });
        if(result && result.data && result.data.data && result.data.data.airboxDetails && result.data.data.airboxDetails.state){
          if(!result.data.data.airboxDetails.isActive){
            setMsgClass('error-msg-cls');
            setMsg('AirBox not active at the moment, please try again later');
            setState(3);
          }else{
            if(result.data.data.airboxDetails.state === 4){
              setIsProcessing(true);
              setMsgClass('error-msg-cls');
              if(msg && !msg.includes('Closing') && !msg.includes('Opening')){
                setMsg("Processing");
              }
              setRetry(retry - 1);
            }else {
              if(result.data.data.airboxDetails.state !== 3){
                setMsgClass('success-msg-cls');
                if(msg !== ''){
                  setMsg(result.data.data.airboxDetails.state === 1 ? 'Opened' : 'Closed');
                }
                setIsProcessing(false);
                setRetry(0);
              }else{
                setMsgClass('error-msg-cls');
                setMsg('AirBox not active at the moment, please try again later');
              }
              setState(result.data.data.airboxDetails.state);
            }
          }
        }else{
          localStorage.clear();
          return navigate('/PageNotFound');
        }
      } catch (error) {
        localStorage.clear();
        return navigate('/PageNotFound');
      }
    }

    if (retry <= 0 && isProcessing) {
      setMsgClass('error-msg-cls');
      setMsg('AirBox not active at the moment, please try again later');
      setState(3);
      return;
    }else if(retry <= 0 && (state === 1 || state === 2)){
      return;
    }
    const intervalId = setInterval(fetchData, 4000);
    return () => clearInterval(intervalId);

    
  }}, [retry])


  const openCloseAirBox = async (e) => {
    e.preventDefault();
    try {
      if(!id || !token || isProcessing){
        localStorage.clear();
        return navigate('/PageNotFound');
      }

      const updatedState = state === 1 ? 2 : 1;

      const existingResult = await axios.get(`${process.env.REACT_APP_AIRBOX_BE_URL}/airbox/getLiveState?airboxId=${id}`, {
        headers: {
          'authorization': `Bearer ${token}`,
          'content-language': 'en',
          'utcoffset': 330,
        }
      });

      if(existingResult && existingResult.data && existingResult.data.data && existingResult.data.data.airboxDetails && existingResult.data.data.airboxDetails.state && existingResult.data.data.airboxDetails.state === updatedState){
        setMsgClass('success-msg-cls');
        setMsg(updatedState === 1 ? 'Opened' : 'Closed');
        setState(updatedState);
        setIsProcessing(false);
        setRetry(0);
        return;
      }

      const result = await axios.put(`${process.env.REACT_APP_AIRBOX_BE_URL}/customer/openOrCloseAirbox`, {
        serialNumber: id,
        state: updatedState
      }, {
        headers: {
          'authorization': `Bearer ${token}`,
          'content-language': 'en',
          'utcoffset': 330,
        }
      });
      if(result && result.data && result.data.statusCode === 200){
        setMsgClass('error-msg-cls');
        setIsProcessing(true); 
        setMsg(updatedState === 1 ? 'Opening' : 'Closing');
        setRetry(8);
      }else{
        localStorage.clear();
        return navigate('/PageNotFound');
      }
    } catch (error) {
      if(error && error.response && error.response.data && error.response.data.message === "Processing, Please try again in a few seconds"){
        setMsgClass('error-msg-cls');
        setMsg("AirBox is Processing, Please try again later");
      }else{
        localStorage.clear();
        return navigate('/PageNotFound');
      }
    }
  }

  return (
    <div className="Login-form-container">
    <div> 
      <div className="Parent-img">
        <img src='./logo.png' className="logo-img-cls"></img>
      </div>
      <form className="Login-form">
        <div className="Login-form-content">
          <h3 className="Home-form-title">AirBox Guest</h3>
          <div className="form-group mt-3">
            <label>Serial Number</label>
            <input
              type="mobile"
              className="form-control mt-1"
              placeholder="Enter Phone Number"
              value={id}
              readOnly
            />
          </div>
          <div className="Home-button-main">
            <div className="Home-form-button">
              <button type="submit" className="btn btn-primary" onClick={openCloseAirBox} disabled={isProcessing || state === 3 || state === 5}>
                {state === 1 ? 'CLOSE' : state === 3 ? 'ERROR' : state === 5 ? 'Initializing' : 'OPEN'}
              </button>
            </div>
          </div>
          <p className={msgClass}>{msg} {loaderMsgArray.includes(msg) ? <img src="https://airbox-bkt.s3.amazonaws.com/loader.gif" width="30px"></img> : ''}</p>
        </div>
      </form>
    </div>
  </div>
  )
}

export default Home