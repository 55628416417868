import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

const Login = (props) => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token'));
  useEffect(() =>{{
    if(token){
      return navigate('/home');
    }
  }}, [])
  let [mobile] = useState('');
  const [otp, setOTP] = useState('');
  const [msg, setMsg] = useState('');
  const [msgClass, setMsgClass] = useState('error-msg-cls');
  const search = window.location.search;
  const params = new URLSearchParams(search);
  mobile = params.get('mobile');
  let id = params.get('id');
  useEffect(() =>{{
    if(!mobile || !id){
      return navigate('/PageNotFound');
    }
  }}, [])

  const Auth = async (e) => {
      e.preventDefault();
      try {
          const result = await axios.post(`${process.env.REACT_APP_AIRBOX_BE_URL}/user/login/guest/otp`, {
              mobile,
              otp,
              'deviceType': 'WEB',
              'airboxId': id
          }, {
            headers: {
              'content-language': 'en',
              'utcoffset': 330,
            }
          });
          if(result && result.data && result.data.data && result.data.data.token){
            localStorage.setItem('token', JSON.stringify(result.data.data.token));  
            localStorage.setItem('id', JSON.stringify(id));  
            setMsgClass('success-msg-cls');
            setMsg('Success');
            return navigate('/home');
          }
          setMsg('Invalid Request - Please try again');
      } catch (error) {
        if(error.response && error.response.data && error.response.data.message === "Invalid OTP"){
          setMsg('Invalid OTP - Please try again');
        }else{
          setMsg('Invalid Request - Please try again');
        }
      }
  }

  return (
    <div className="Login-form-container">
      <div> 
        <div className="Parent-img">
          <img src='./logo.png' className="logo-img-cls"></img>
        </div>
        <form className="Login-form" onSubmit={Auth}>
          <div className="Login-form-content">
            <h3 className="Login-form-title">Sign In</h3>
            <div className="form-group mt-3">
              <label>Phone Number</label>
              <input
                type="mobile"
                className="form-control mt-1"
                placeholder="Enter Phone Number"
                value={mobile}
                readOnly
              />
            </div>
            <div className="form-group mt-3">
              <label>OTP</label>
              <input
                type="text" 
                inputMode="numeric" 
                autoComplete="one-time-code"
                pattern = "^[0-9]{4}$"
                className="form-control mt-1"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
                required
              />
            </div>
            <div className="login-btn-main">
              <button type="submit" className="btn btn-primary login-btn">
                LOGIN
              </button>
            </div>
            <p className={msgClass}>{msg}</p>
          </div>
        </form>
        </div>
    </div>
  )
}

export default Login